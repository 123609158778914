import { fork } from "redux-saga/effects";
import { authSaga } from "./authSaga";
import { userSaga } from "./userSaga";
import { leavesSaga } from "./leavesSaga";
import { holidaySaga } from "./holidaySaga";
import { salarySettingSaga } from "./salarySettingsSaga";
import { salaryListSaga } from "./salaryListSaga";
import { assetsSaga } from "./assetsSaga";
import { assignSaga } from "./assignSaga";

export default function* rootSaga() {
  yield fork(authSaga);
  yield fork(userSaga);
  yield fork(leavesSaga);
  yield fork(holidaySaga);
  yield fork(salarySettingSaga);
  yield fork(salaryListSaga);
  yield fork(assetsSaga);
  yield fork(assignSaga);
}
