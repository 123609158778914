import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { addAssets, deleteAssets, fetchAssets, getAsset, updateAssets } from "../api";

import { ASSETS_FETCH_LOADING, ASSETS_ADD_SUCCESS,
     ASSETS_ADD_ERROR, ASSETS_ADD_LOADING, 
     ASSETS_UPDATE_LOADING,
     ASSETS_UPDATE_SUCCESS,
     ASSETS_UPDATE_ERROR,
     ASSETS_FETCH_SUCCESS,
     ASSETS_FETCH_ERROR,
     ASSETS_DELETE_SUCCESS,
     ASSETS_DELETE_ERROR,
     ASSETS_GET_LOADING,
     ASSETS_GET_ERROR,
     ASSETS_GET_SUCCESS} from "../redux/types";
import { Constant } from "src/lib/Constants";

function* assetsFetch(action) {
    // console.log(action.payload);
    // console.log("leaves called");
    yield put({
      type: ASSETS_FETCH_LOADING,
      payload: true,
    });
    try {
      let res = yield call(fetchAssets);
      let response = res.response.data;
      // console.log(response.result)
      if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
        yield put({
          type: ASSETS_FETCH_SUCCESS,
          payload: response.result,
        });
      } else {
        yield put({
          type: ASSETS_FETCH_ERROR,
          payload: response.message,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }
//   add asset
function* addAssetFunction(action) {
    // console.log(action.payload,'ll');
    yield put({
      type: ASSETS_ADD_LOADING,
      payload: true,
    });
    try {
      let res = yield call(addAssets, action.payload);
      let response = res.response.data;
      if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
        yield put({
          type: ASSETS_ADD_SUCCESS,
          payload: response,
        });
      } else {
        yield put({
          type: ASSETS_ADD_ERROR,
          payload: response.message,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }
  
  function* AssetGet(action) {
    // console.log(action.payload);
    yield put({
      type: ASSETS_GET_LOADING,
      payload: true,
    });
    try {
      let res = yield call(getAsset, action.payload);
      let response = res.response.data;
      if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
        yield put({
          type: ASSETS_GET_SUCCESS,
          payload: response.result,
        });
      } else {
        yield put({
          type: ASSETS_GET_ERROR,
          payload: response.message,
        });
      }
    } catch (error) {
      // console.log(error);
      // yield put({
      //   type: LEAVE_GET_ERROR,
      //   payload: error.toString(),
      // });
    }
  }
//   update asset
function* updateAssetFunction(action) {
    //  console.log(action.payload,'u');
    yield put({
      type: ASSETS_UPDATE_LOADING,
      payload: true,
    });
    try {
      let res = yield call(updateAssets, action.payload);
      let response = res.response.data;
      if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
        yield put({
          type: ASSETS_UPDATE_SUCCESS,
          payload: response.message,
        });
      } else {
        yield put({
          type: ASSETS_UPDATE_ERROR,
          payload: response.message,
        });
      }
    } catch (error) {
      // console.log(error);
      // yield put({
      //   type: LEAVES_UPDATE_ERROR,
      //   payload: error.toString(),
      // });
    }
  }

  function* assetDelete(action) {
    // console.log(action.payload);
  
    try {
      let res = yield call(deleteAssets, action.payload);
      let response = res.response.data;
      // console.log(response.message);
      if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
        // console.log(response.message);
        yield put({
          type: ASSETS_DELETE_SUCCESS,
          payload: response.message,
        });
      } else {
        yield put({
          type: ASSETS_DELETE_ERROR,
          payload: response.message,
        });
      }
    } catch (error) {
      // console.log(error);
      // yield put({
      //   type: LEAVES_DELETE_ERROR,
      //   payload: error.toString(),
      // });
    }
  }

  
    export function* assetsSaga(){
      yield takeEvery("ASSETS_FETCH_ACTION", assetsFetch);
      yield takeLatest("ASSETS_DELETE_ACTION", assetDelete);
yield takeLatest("ASSETS_GET_ACTION",AssetGet)
    yield takeLatest("ASSETS_ADD_ACTION",addAssetFunction);
    yield takeLatest("ASSETS_UPDATE_ACTION",updateAssetFunction);
  }