import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  assignAsset,
  deleteAssign,
  getAllAssignAsset,
  getAssign,
 updateAssign,
} from "src/api";

import { Constant } from "src/lib/Constants";
import {
  ASSIGN_ASSET_ADD_ERROR,
  ASSIGN_ASSET_ADD_LOADING,
  ASSIGN_ASSET_ADD_SUCCESS,
  ASSIGN_ASSET_DELETE_ERROR,
  ASSIGN_ASSET_DELETE_SUCCESS,
  // ASSIGN_ASSET_FETCH_ACTION,
  ASSIGN_ASSET_FETCH_ERROR,
  ASSIGN_ASSET_FETCH_LOADING,
  ASSIGN_ASSET_FETCH_SUCCESS,
  ASSIGN_ASSET_GET_ERROR,
  ASSIGN_ASSET_GET_LOADING,
  ASSIGN_ASSET_GET_SUCCESS,
  ASSIGN_ASSET_UPDATE_ERROR,
  ASSIGN_ASSET_UPDATE_LOADING,
  ASSIGN_ASSET_UPDATE_SUCCESS,
  // ASSIGN_ASSET_UPDATE_LOADING,
  // ASSIGN_ASSET_UPDATE_SUCCESS,
  // ASSIGN_ASSET_UPDATE_ERROR,
} from "src/redux/types";
// fetch all assets
function* assignFetch(action) {
  // console.log(action.payload);
  // console.log("leaves called");
  yield put({
    type: ASSIGN_ASSET_FETCH_LOADING,
    payload: true,
  });
  try {
    let res = yield call(getAllAssignAsset);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: ASSIGN_ASSET_FETCH_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: ASSIGN_ASSET_FETCH_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
  }
}
// get a asset
function* assignGet(action) {
  yield put({
    type: ASSIGN_ASSET_GET_LOADING,
    payload: true,
  });
  try {
    let res = yield call(getAssign, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: ASSIGN_ASSET_GET_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: ASSIGN_ASSET_GET_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVE_GET_ERROR,
    //   payload: error.toString(),
    // });
  }
}
//   add asset
function* addAssignFunction(action) {
  yield put({
    type: ASSIGN_ASSET_ADD_LOADING,
    payload: true,
  });
  try {
    let res = yield call(assignAsset, action.payload);
    // let response = res.response.response.data;
    let response =res.response.data;
    console.log(res)
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: ASSIGN_ASSET_ADD_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: ASSIGN_ASSET_ADD_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    console.log(error)
    // yield put({
    //   type: ASSIGN_ASSET_ADD_ERROR,
    //   payload: error.message,
    // });  
    }
}

function* assignDelete(action) {
  // console.log(action.payload);

  try {
    let res = yield call(deleteAssign, action.payload);
    let response = res.response.data;
    // console.log(response.message);
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      // console.log(response.message);
      yield put({
        type: ASSIGN_ASSET_DELETE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: ASSIGN_ASSET_DELETE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_DELETE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* updateAssignFunction(action) {
  //  console.log(action.payload,'u');
  yield put({
    type: ASSIGN_ASSET_UPDATE_LOADING,
    payload: true,
  });
  try {
    let res = yield call(updateAssign, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: ASSIGN_ASSET_UPDATE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: ASSIGN_ASSET_UPDATE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: ASSIGN_ASSET_UPDATE_ERROR,
    //   payload: response.message,
    // });
  }
}

export function* assignSaga() {
  yield takeEvery("ASSIGN_ASSET_FETCH_ACTION", assignFetch);
  yield takeLatest("ASSIGN_ASSET_DELETE_ACTION", assignDelete);
  yield takeLatest("ASSIGN_ASSET_GET_ACTION", assignGet);
  yield takeLatest("ASSIGN_ASSET_ADD_ACTION", addAssignFunction);
  yield takeLatest("ASSIGN_ASSET_UPDATE_ACTION",updateAssignFunction)
}
