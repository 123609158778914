import { ASSIGN_ASSET_ADD_ERROR, ASSIGN_ASSET_ADD_LOADING, ASSIGN_ASSET_ADD_SUCCESS, 
  ASSIGN_ASSET_DELETE_ERROR, 
  ASSIGN_ASSET_DELETE_SUCCESS, 
  ASSIGN_ASSET_FETCH_ERROR, 
  ASSIGN_ASSET_FETCH_LOADING, 
  ASSIGN_ASSET_FETCH_SUCCESS,
  ASSIGN_ASSET_GET_ERROR,
  ASSIGN_ASSET_GET_LOADING,
  ASSIGN_ASSET_GET_SUCCESS,
  ASSIGN_ASSET_UPDATE_ERROR,
  ASSIGN_ASSET_UPDATE_LOADING,
  ASSIGN_ASSET_UPDATE_SUCCESS,
  RESET_MESSAGES,
  RESET_RESULT} from "../types";

  
  const initialState = {
    loading: false,
    page_loading: false,
    result: [],
    error: "",
    addUpdateError: "",
    assignDeleteMessage: "",
    assignUpdateMessage: "",
  };
  
  const assignassetReducer = (state = initialState, action) => {
    switch (action.type) {
     
      case ASSIGN_ASSET_FETCH_LOADING:
        return{
          ...state,
          loading:action.payload,
          error:""
        };
      case ASSIGN_ASSET_FETCH_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload,
        };
      case ASSIGN_ASSET_FETCH_ERROR:
        return {
          ...state,
          loading: false,
          result: [],
          error: action.payload,
        };
      
      case ASSIGN_ASSET_DELETE_SUCCESS:
        return{
          ...state,
          assignDeleteMessage:action.payload
        }
      
        case ASSIGN_ASSET_DELETE_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case ASSIGN_ASSET_ADD_LOADING:
        return {
          ...state,
          loading: action.payload,
          addUpdateError: "",
        };
      case ASSIGN_ASSET_ADD_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload,
        };
      case ASSIGN_ASSET_ADD_ERROR:
        return {
          ...state,
          loading: false,
          result: [],
          addUpdateError: action.payload,
        };
        case ASSIGN_ASSET_GET_LOADING:
          return {
            ...state,
            page_loading: action.payload,
            loading: action.payload,
          };
        case ASSIGN_ASSET_GET_SUCCESS:
          return {
            ...state,
            loading: false,
            page_loading: false,
            result: action.payload,
          };
        case ASSIGN_ASSET_GET_ERROR:
          return {
            ...state,
            loading: false,
            page_loading: false,
            result: [],
            error: action.payload,
          };

        case ASSIGN_ASSET_UPDATE_LOADING:
          return {
            ...state,
            loading: action.payload,
            addUpdateError: "",
          };
        case ASSIGN_ASSET_UPDATE_SUCCESS:
          return{
            ...state,
            loading:false,
            assignUpdateMessage:action.payload
          };
        case ASSIGN_ASSET_UPDATE_ERROR:
          return{
            ...state,
            loading:false,
            addUpdateError:action.payload
          }
        case RESET_RESULT:
        return {
          ...state,
          loading: false,
          result: [],
          error: "",
          assignDeleteMessage: "",
          addUpdateError: "",
          assignUpdateMessage:""
        };
      case RESET_MESSAGES:
        return {
          ...state,
          error: "",
          assetDeleteMessage: "",
          assignUpdateMessage: "",
          addUpdateError: "",
        };
  
      default:
        return state;
    }
  };
  
  export default assignassetReducer;
  