import {
  AVAILABLE_LEAVES_GET_LOADING,
  AVAILABLE_LEAVES_GET_SUCCESS,
  AVAILABLE_LEAVES_GET_ERROR,
  APPROVED_LEAVES_GET_LOADING,
  APPROVED_LEAVES_GET_SUCCESS,
  APPROVED_LEAVES_GET_ERROR,
  CALCULATED_SALARY_ADD_LOADING,
  CALCULATED_SALARY_ADD_SUCCESS,
  CALCULATED_SALARY_ADD_ERROR,
  CALCULATED_SALARY_GET_LOADING,
  CALCULATED_SALARY_GET_SUCCESS,
  CALCULATED_SALARY_GET_ERROR,
  CALCULATED_SALARY_DELETE_LOADING,
  CALCULATED_SALARY_DELETE_SUCCESS,
  CALCULATED_SALARY_DELETE_ERROR,
  CALCULATED_SALARY_GET_PDF_LOADING,
  CALCULATED_SALARY_GET_PDF_SUCCESS,
  CALCULATED_SALARY_GET_PDF_ERROR,
  // SALARY_RANGE_GET_LOADING,
  // SALARY_RANGE_GET_ERROR,
  SALARY_RANGE_GET_SUCCESS,
  RESET_MESSAGES,
  RESET_RESULT,
  RESET_PDF_RESULTS,
  CHECK_SALARY_ADD_LOADING,
  CHECK_SALARY_ADD_SUCCESS,
  CHECK_SALARY_ADD_ERROR,
  LEAVE_BALANCE_GET_SUCCESS,
} from "../types";

const initialState = {
  loading: false,
  page_loading: false,
  addLoading: false,
  calculateLoading:false,
  leaveResult: [],
  availableLeaveResult: [],
  result: [],
  calculationResult:"",
  pdfResult: "",
  error: "",
  addCalculatedSalaryError: "",
  checkSalaryError:"",
  salaryDeletedMessage: "",
  balanceResult:""
};

const salaryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case AVAILABLE_LEAVES_GET_LOADING:
      return {
        ...state,
        page_loading: action.payload,
        error: "",
      };
    case AVAILABLE_LEAVES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        availableLeaveResult: action.payload,
      };
    case LEAVE_BALANCE_GET_SUCCESS:
      return{
        ...state,
        loading:false,
        balanceResult:action.payload
      }
    case AVAILABLE_LEAVES_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case APPROVED_LEAVES_GET_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case APPROVED_LEAVES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        leaveResult: action.payload,
      };
    case APPROVED_LEAVES_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case CHECK_SALARY_ADD_LOADING:
      return {
        ...state,
        calculateLoading: action.payload,
        checkSalaryError: "",
      };
      case CHECK_SALARY_ADD_SUCCESS:
        return {
          ...state,
          calculateLoading: false,
          calculationResult: action.payload,
        };
        case CHECK_SALARY_ADD_ERROR:
          return{
            ...state,
            calculateLoading: false,
            checkSalaryError: action.payload,
          }
    case CALCULATED_SALARY_ADD_LOADING:
      return {
        ...state,
        addLoading: action.payload,
        addCalculatedSalaryError: "",
      };
    case CALCULATED_SALARY_ADD_SUCCESS:
      return {
        ...state,
        addLoading: false,
        result: action.payload,
      };
    case CALCULATED_SALARY_ADD_ERROR:
      return {
        ...state,
        addLoading: false,
        addCalculatedSalaryError: action.payload,
      };
    case CALCULATED_SALARY_GET_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case CALCULATED_SALARY_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    case CALCULATED_SALARY_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CALCULATED_SALARY_DELETE_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case CALCULATED_SALARY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        salaryDeletedMessage: action.payload,
      };
    case CALCULATED_SALARY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CALCULATED_SALARY_GET_PDF_LOADING:
      return {
        ...state,
        page_loading: action.payload,
      };
    case CALCULATED_SALARY_GET_PDF_SUCCESS:
      return {
        ...state,
        pdfResult: action.payload,
        page_loading: false,
      };
    case CALCULATED_SALARY_GET_PDF_ERROR:
      return {
        ...state,
        page_loading: false,
        error: action.payload,
      };
    case RESET_RESULT:
      return {
        ...state,
        leaveResult: [],
        availableLeaveResult: [],
        result: [],
        pdfResult: "",
        error: "",
        salaryDeletedMessage: "",
        addCalculatedSalaryError: "",
        calculationResult:"",
        checkSalaryError: "",
        balanceResult:""
      };
      case SALARY_RANGE_GET_SUCCESS:
          return{
            ...state,
            loading: false,
            result: action.payload,
          };

    case RESET_MESSAGES:
      return {
        ...state,
        error: "",
        salaryDeletedMessage: "",
        addCalculatedSalaryError: "",
        checkSalaryError: "",
        balanceResult:""
      };
    case RESET_PDF_RESULTS:
      return {
        ...state,
        pdfResult: "",
      };
    default:
      return state;
  }
};

export default salaryListReducer;
