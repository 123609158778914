import axios from "axios";

export function loginUser(data) {
  return axios
    .post("user/login", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function registerUser(data) {
  return axios
    .post("user/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function fetchUsers() {
  return axios
    .get("user/")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function deleteUser(id) {
  // console.log(id);
  return axios
    .delete("user/", { data: { userId: id } })
    .then(function (response) {
      // console.log(response);
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

// export function statusUpdate(id){
//   return axios
//     .post("user/", { data: { userId: id } })
//     .then(function (response) {
//       return { response };
//     })
//     .catch(function (error) {
//       // console.log(error);
//       return { error };
//     });
// }

export function loggedInUser(data) {
  return axios
    .post("user/loggedInUser", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function logOutUser() {
  return axios
    .get("user/logout")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function changeUserStatus(items) {
  return axios
    .patch("user/", { userId: items.userId, status: items.status })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function updateUser(data) {
  return axios
    .put("user/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getUser(data) {
  return axios
    .get(`user/${data.userId}`)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function changeUserPassword(items) {
  return axios
    .patch("user/changePassword", {
      oldPassword: items.oldPassword,
      newPassword: items.newPassword,
    })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function sendVerificationCode(data) {
  return axios
    .post("user/forgotPassword", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function verifyCode(data) {
  return axios
    .post("user/verifyCode", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function updateUserPassword(items) {
  return axios
    .patch("user/updatePassword", {
      email: items.email,
      verificationCode: items.verificationCode,
      password: items.password,
    })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
// assets 

export function fetchAssets() {
  return axios
    .get("assets/")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
export function addAssets(data) {
  console.log('ok',data)
  return axios
    .post("assets", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function updateAssets(data) {
  return axios
    .put("assets", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
export function deleteAssets(id) {
  // console.log(id);
  return axios
    .delete(`assets/${id}`)
    .then(function (response) {
      // console.log(response);
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
// get asset 
export function getAsset(data) {
  return axios
    .get(`assets/${data.assetId}`)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
// assign assets api 
export function getAllAssignAsset(){
  return axios
  .get("assignAsset/getAll")
  .then(function (response) {
    return { response };
  })
  .catch(function (error) {
    // console.log(error);
    return { error };
  });
}

export function getAssign(data) {
  return axios
    .get(`assignAsset/getByAssignAssetId/${data.assetId}`)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
export function assignAsset(data) {
  return axios
    .post("assignAsset", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function deleteAssign(id) {
  // console.log(id);
  return axios
    .delete(`assignAsset/${id}`)
    .then(function (response) {
      // console.log(response);
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
export function updateAssign(data) {
  return axios
    .put("assignAsset/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
//-----------leaves api calls----------
export function fetchLeaves() {
  return axios
    .get("leaves/")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function deleteLeave(id) {
  // console.log(id);
  return axios
    .delete("leaves/", { data: { leaveId: id } })
    .then(function (response) {
      // console.log(response);
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function addLeaves(data) {
  return axios
    .post("leaves/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function updateLeave(data) {
  return axios
    .put("leaves/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

// update leave status 

export function updateLeaveStatus(leaveId) {
  console.log('leaveidd',leaveId)
  return axios
    .put(`leaves/approveLeave/${leaveId}` )
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function rejectLeaveStatus(leaveId) {
  console.log('reject id ',leaveId)
  return axios
    .put(`leaves/rejectLeave/${leaveId}` )
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
export function getLeave(data) {
  // console.log(data);
  let url = `leaves/getLeave/${data.leaveId}`;
  // if (data.fromDate && data.toDate) {
    // url = `leaves/${data.leaveId}?fromDate=${data.fromDate}&&toDate=${data.toDate}`;
  // }
  return axios
    .get(url)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

//-----------------Holiday Apis-------
export function fetchHolidays() {
  return axios
    .get("holidays/")
    .then(function (response) {
      // console.log(response);
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function deleteHoliday(id) {
  // console.log(id);
  return axios
    .delete("holidays/", { data: { holidayId: id } })
    .then(function (response) {
      // console.log(response);
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function addHoliday(data) {
  return axios
    .post("holidays/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function updateHoliday(data) {
  return axios
    .put("holidays/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getHoliday(data) {
  return axios
    .get(`holidays/${data.holidayId}`)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

//----------------salary schema-----------
export function updateSalarySettings(data) {
  return axios
    .put("salarySettings/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getSalarySettings(data) {
  return axios
    .get("salarySettings/")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

//--------------salary list--------------

export function getApprovedSalaryList(data) {
  return axios
    .post("calculateSalary/user-leaves-list", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getAvailableSalaryList(data) {
  return axios
    .post("calculateSalary/available-leave", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
// Leave balance leave

export function getLeaveBalance(data) {
  console.log('user id on leave',data)
  return axios
    .get(`leaves/available-balance/${data}`)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      console.log(error);
      return { error };
    });
}
export function addCalculatedSalary(data) {
  // console.log(data);
  return axios
    .post("calculateSalary/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function checkSalary(data){
  return axios.post("calculateSalary/checkCalculatedSalary",data)
  .then(function (response){
    return {response}
  })
  .catch(function(error){
    return{error}
  })
}
export function getCalculatedSalary() {
  return axios
    .get("calculateSalary")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function deleteCalculatedSalary(id) {
  return axios
    .delete("calculateSalary/", { data: { id: id } })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function generateCalculatedSalaryPDF(data) {
  return axios
    .get("calculateSalary/view-salary/" + data, { responseType: "blob" })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getSalaryRangeData(data){
  return axios.post("calculateSalary/range",data)
  .then(function(response){
    return{response};
  })
  .catch(function (error){
    return {error};
  })
}

//-----------------------dashboard----------------------
export function UpcomingHolidays() {
  return axios
    .get("/dashboard/holidays")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function UpcomingLeaves() {
  return axios
    .get("/dashboard/leaves")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function UpcomingEvents() {
  return axios
    .get("/dashboard/events")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
