import { ASSETS_ADD_ERROR, ASSETS_ADD_LOADING, ASSETS_ADD_SUCCESS, ASSETS_DELETE_ERROR, ASSETS_DELETE_SUCCESS, ASSETS_FETCH_ERROR, ASSETS_FETCH_LOADING, ASSETS_FETCH_SUCCESS, ASSETS_GET_ERROR, ASSETS_GET_LOADING, ASSETS_GET_SUCCESS, ASSETS_UPDATE_ERROR, ASSETS_UPDATE_LOADING, ASSETS_UPDATE_SUCCESS, RESET_MESSAGES, RESET_RESULT } from "../types";

  
  const initialState = {
    loading: false,
    page_loading: false,
    result: [],
    error: "",
    addUpdateAssetError: "",
    assetDeleteMessage: "",
    assetUpdateMessage: "",
  };
  
  const assetReducer = (state = initialState, action) => {
    switch (action.type) {
      case ASSETS_FETCH_LOADING:
        return {
          ...state,
          loading: action.payload,
          error: "",
        };
      case ASSETS_FETCH_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload,
        };
      case ASSETS_FETCH_ERROR:
        return {
          ...state,
          loading: false,
          result: [],
          error: action.payload,
        };
      
      case ASSETS_DELETE_SUCCESS:
        return {
          ...state,
          assetDeleteMessage: action.payload,
        };
      case ASSETS_DELETE_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case ASSETS_ADD_LOADING:
        return {
          ...state,
          loading: action.payload,
          addUpdateAssetError: "",
        };
      case ASSETS_ADD_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload,
        };
      case ASSETS_ADD_ERROR:
        return {
          ...state,
          loading: false,
          result: [],
          addUpdateAssetError: action.payload,
        };
      case ASSETS_UPDATE_LOADING:
        return {
          ...state,
          loading: action.payload,
          addUpdateAssetError: "",
        };
      case ASSETS_UPDATE_SUCCESS:
        return {
          ...state,
          loading: false,
          assetUpdateMessage: action.payload,
        };
      case ASSETS_UPDATE_ERROR:
        return {
          ...state,
          loading: false,
          addUpdateAssetError: action.payload,
        };
      case ASSETS_GET_LOADING:
        return {
          ...state,
          page_loading: action.payload,
          loading: action.payload,
        };
      case ASSETS_GET_SUCCESS:
        return {
          ...state,
          loading: false,
          page_loading: false,
          result: action.payload,
        };
      case ASSETS_GET_ERROR:
        return {
          ...state,
          loading: false,
          page_loading: false,
          result: [],
          error: action.payload,
        };
      case RESET_RESULT:
        return {
          ...state,
          loading: false,
          result: [],
          error: "",
          assetDeleteMessage: "",
          assetUpdateMessage: "",
          addUpdateAssetError: "",
        };
      case RESET_MESSAGES:
        return {
          ...state,
          error: "",
          assetDeleteMessage: "",
          assetUpdateMessage: "",
          addUpdateAssetError: "",
        };
  
      default:
        return state;
    }
  };
  
  export default assetReducer;
  